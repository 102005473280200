import React from "react";
import logo from "../img/logo-smaller.png";

class NavMenu extends React.Component {
  constructor() {
    super();
  }
  render() {
    const { nav, onNav, activeNav, showHideMenu } = this.props;

    return (
      <div className="menu-nav">
        <div className="menu-nav-logo" onClick={showHideMenu}>
          <div className="menu-nav-mobile-drop">
            <div className="mobile-drop" />
            <div className="mobile-drop" />
            <div className="mobile-drop" />
          </div>
          <img src={logo} alt="logo" />
          <div className="menu-nav-logo--text">Lionheart</div>
        </div>
        <div className={`menu-nav-items ${activeNav ? "active" : ""}`}>
          <div className="nav-item">
            <a
              className={`${nav === "" || nav === "home" ? "active" : ""}`}
              href="#home"
              onClick={() => onNav("home")}
            >
              home
            </a>
          </div>
          <div className="nav-item">
            <a
              className={`${nav === "about-us" ? "active" : ""}`}
              href="#about-us"
              onClick={() => onNav("about-us")}
            >
              About us
            </a>
          </div>
          <div className="nav-item">
            <a
              className={`${nav === "contact" ? "active" : ""}`}
              href="#contact"
              onClick={() => onNav("contact")}
            >
              Contact
            </a>
          </div>
          <div className="nav-item">
            <a
              className={`${nav === "gear" ? "active" : ""}`}
              href="#gear"
              onClick={() => onNav("gear")}
            >
              Gear
            </a>
          </div>
        </div>
        <div className="menu-nav-social">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/lionheartathletic/"
            data-reactid=".0.8.3.0.0"
          >
            <svg
              className="c2d"
              viewBox="0 0 10 24"
              data-reactid=".0.8.3.0.0.0"
            >
              <path
                d="M2 4v3H0v4h2v11h5V11h3c-.42-.006-.14-1.78 0-4H7V5c-.382-.634.103-1.143 1-1h2V0H7C2.05 0 2.162 3.708 2 4"
                data-reactid=".0.8.3.0.0.0.0"
              />
            </svg>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/lionheart_athletic/"
            data-reactid=".0.8.3.0.2"
          >
            <svg
              className="c2d"
              viewBox="0 0 24 24"
              data-svgreactloader='[["http://www.w3.org/2000/svg","xlink","http://www.w3.org/1999/xlink"]]'
              data-reactid=".0.8.3.0.2.0"
              xlink="http://www.w3.org/1999/xlink"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>
        </div>
      </div>
    );
  }
}

export default NavMenu;
