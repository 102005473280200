import React from "react";
import ScheduleASession from "./ScheduleASession";
class SignUp extends React.Component {
  render() {
    const sessionEmail = window.localStorage.getItem("lionheart_id");

    const {
      submitted,
      name,
      email,
      phone,
      onSubmit,
      onSendAdditional,
      onKeyPress,
      personalMessage
    } = this.props;

    return (
      <>
        <section id="become" className="colyma-section">
          <ScheduleASession />
          <div className="join-form clickable-z">
            <div className="join-form-row">
              <label>Name</label>
              <input
                value={name}
                onChange={e => onKeyPress("name", e.target.value)}
              />
            </div>
            <div className="join-form-row">
              <label>Email</label>
              <input
                value={email}
                onChange={e => onKeyPress("email", e.target.value)}
              />
            </div>
            <div className="join-form-row">
              <label>Phone</label>
              <input
                value={phone}
                onChange={e => onKeyPress("phone", e.target.value)}
              />
            </div>
            <div className="join-form-row">
              <label>Message</label>
              <textarea
                rows={4}
                value={personalMessage}
                onChange={e => onKeyPress("personalMessage", e.target.value)}
              />
            </div>
            <button className="submit-button" onClick={onSubmit}>
              Submit
            </button>
            {submitted && <p>Thanks for reaching out!</p>}
          </div>
        </section>
      </>
    );
  }
}
export default SignUp;
