import React, { Component } from "react";
import axios from "axios";
import ScrollableAnchor from "react-scrollable-anchor";
import "./App.scss";

import NavMenu from "./components/NavMenu";
import LionheartHeader from "./sections/LionheartHeader";
import JoinThePride from "./sections/JoinThePride";
import ThreePillars from "./sections/ThreePillars";
import AboutUs from "./sections/AboutUs";
import Gear from "./sections/Gear";
import SignUp from "./sections/SignUp";
import Footer from "./components/Footer";

class App extends Component {
  constructor() {
    super();

    const cachedEmail = window.localStorage.getItem("lionheart_id");

    this.state = {
      nav: 1,
      email: cachedEmail || "",
      name: "",
      phone: "",
      personalMessage: "",
      submitted: false,
      activeNav: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onReachOut = this.onReachOut.bind(this);
    this.onSendAdditional = this.onSendAdditional.bind(this);
  }

  handleInputChange(e, prop) {
    this.setState({
      [prop]: e.target.value
    });
  }

  onReachOut() {
    const { email, name, phone, personalMessage } = this.state;
    window.localStorage.setItem("lionheart_id", email);

    console.log("here");
    console.log(personalMessage);

    axios
      .post("https://qhfboag4ya.execute-api.us-east-1.amazonaws.com/Prod/", {
        email,
        name,
        phone,
        personalMessage
      })
      .then(response => {
        console.log(response);
        this.setState({
          submitted: true,
          personalMessage: ""
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  onSendAdditional() {
    const { name, personalMessage } = this.state;
    const email = window.localStorage.getItem("lionheart_id");

    console.log("additional");
    console.log(personalMessage);

    axios
      .post("/lionheart/email", {
        email,
        name,
        personalMessage
      })
      .then(response => {
        console.log(response);
        this.setState({
          personalMessage: ""
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const newNav = decodeURI(window.location.hash).replace("#", "");
    this.setState({
      nav: newNav
    });
  };

  render() {
    const {
      nav,
      name,
      email,
      phone,
      submitted,
      personalMessage,
      activeNav
    } = this.state;

    return (
      <div className="App">
        <NavMenu
          nav={nav}
          onNav={p => {
            this.setState({
              nav: p
            });
          }}
          activeNav={activeNav}
          showHideMenu={() => {
            this.setState({
              activeNav: !activeNav
            });
          }}
        />
        {/* <MobileNavMenu/> */}
        <div
          className="site-content"
          onClick={() =>
            this.setState({
              activeNav: false
            })
          }
        >
          <ScrollableAnchor id="home">
            <LionheartHeader />
          </ScrollableAnchor>

          <JoinThePride />

          <ThreePillars />

          <ScrollableAnchor id="about-us">
            <AboutUs />
          </ScrollableAnchor>

          <ScrollableAnchor id="contact">
            <SignUp
              submitted={submitted}
              name={name}
              email={email}
              phone={phone}
              personalMessage={personalMessage}
              onSubmit={this.onReachOut}
              onSendAdditional={this.onSendAdditional}
              onKeyPress={(field, value) => {
                this.setState({
                  [field]: value
                });
              }}
            />
          </ScrollableAnchor>

          <ScrollableAnchor id="gear">
            <Gear />
          </ScrollableAnchor>

          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
