import React from "react";
import logo from "../img/logo-smaller.png";
import testimonial from "../img/testimonial-1.jpg";

class JoinThePride extends React.Component {
  render() {
    return (
      <section className="colyma-section">
        <img src={logo} className="logo-bg-image right-bg" alt="t" />
        <h1>Join the Pride</h1>
        <p>
          Lionheart Athletic is more than just personal training and nutrition.
          Lionheart Athletic is about personal growth and living life in a way
          you always wanted, finding the strength to do the impossible and
          becoming the best version of yourself you can. Living with the heart
          of a lion!{" "}
        </p>

        <div className="testimonial">
          <img src={testimonial} alt="test-1" className="testimonial-picture" />
          <div className="big-quote">"</div>
          <p>
            <i>
              Changed my whole life! No one better than Colin and I can’t thank
              him enough for making my journey possible.
            </i>
          </p>
          <div style={{ justifyContent: "flex-end" }} className="big-quote">
            "
          </div>
        </div>
      </section>
    );
  }
}

export default JoinThePride;
