import React from "react";
import blackTee from "../img/gear/black-shirt-sm.jpg";
import blackTeeLg from "../img/gear/black-shirt-lg.jpg";
import whiteTee from "../img/gear/white-shirt-sm.jpg";
import whiteTeeLg from "../img/gear/white-shirt-lg.jpg";
import blackSweat from "../img/gear/black-sweat-lg.jpg";
import logo from "../img/logo-smaller.png";

class Gear extends React.Component {
  render() {
    return (
      <section className="colyma-section">
        <img
          src={logo}
          style={{ width: "1200px" }}
          className="logo-bg-image right-bg"
          alt="t"
        />
        <h1>Gear</h1>

        <p>
          All gear is super high quality athletic attire, tri blend. 50%
          polyester, 25% Cotton, 25% rayon.
        </p>
        <div className="gear-items">
          <div className="gear-item">
            <img src={blackTee} alt="logo" className="gear-item-img" />
            <p className="gear-cost">$25</p>
          </div>
          <div className="gear-item">
            <img src={blackTeeLg} alt="logo" className="gear-item-img" />
            <p className="gear-cost">$25</p>
          </div>
          <div className="gear-item">
            <img src={whiteTee} alt="logo" className="gear-item-img" />
            <p className="gear-cost">$25</p>
          </div>
          <div className="gear-item">
            <img src={whiteTeeLg} alt="logo" className="gear-item-img" />
            <p className="gear-cost">$25</p>
          </div>
          <div className="gear-item">
            <img src={blackSweat} alt="logo" className="gear-item-img" />
            <p className="gear-cost">$45</p>
          </div>
        </div>
        <h4>
          <a href="#become" className="clickable-z">
            Register
          </a>{" "}
          and send us a message if you are interested in buying gear
        </h4>
      </section>
    );
  }
}

export default Gear;
