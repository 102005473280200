import React from "react";

const Footer = () => (
  <footer className="colyma-footer">
    <p>{`COPYRIGHT \u00A9 ${new Date().getFullYear()} LIONHEART ATHLETIC LLC, All Rights Reserved`}</p>
    <p>
      <small>
        Icons made by{" "}
        <a
          href="https://www.flaticon.com/authors/elias-bikbulatov"
          target="_blank"
          rel="noopener noreferrer"
        >
          Elias Bikbulatov
        </a>
        {", "}
        <a
          href="https://www.flaticon.com/authors/becris"
          target="_blank"
          rel="noopener noreferrer"
        >
          becris
        </a>
        {", and "}
        <a
          href="https://www.flaticon.com/authors/geotatah"
          target="_blank"
          rel="noopener noreferrer"
        >
          geotatah
        </a>{" "}
        from www.flaticon.com
        <br />
        Developed by:{" "}
        <a
          href="http://stephenshilale.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stephen Shilale
        </a>
      </small>
    </p>
  </footer>
);

export default Footer;
