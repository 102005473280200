import React from "react";

import fitness from "../img/icons/001-gym.png";
import nutrition from "../img/icons/002-nutrition.png";
import reflection from "../img/icons/004-brain.png";
import darkstock from "../img/stock/athlete-barbell-body-949126 copy.jpg";

const ThreePillars = () => (
  <section className="colyma-section">
    <img
      style={{ top: "-25rem" }}
      src={darkstock}
      className="background-image-ath"
      alt="t"
    />
    <div className="section-content">
      <h3 style={{ marginBottom: 0 }}>Always hungry. Never Satisfied.</h3>
      <h2 style={{ marginTop: 0 }}>Constantly Proud</h2>
      <p>
        This is what being Lionhearted is all about. Digging deep and finding
        that fire that pushes you to do things you never thought you could do!
        Be great! Be fearless! Push your limits! Live Lionhearted!
      </p>

      <div className="triple-content">
        <div className="triple-content-box">
          <h3>Fitness</h3>
          <img src={fitness} className="triple-content-icon" alt="t" />
          <p>
            Staying in shape is key to your success. Push yourself further with
            every rep, every set!
          </p>
        </div>
        <div className="triple-content-box">
          <h3>Nutrition</h3>
          <img src={nutrition} className="triple-content-icon" alt="t" />
          <p>
            A balanced diet is necessary for growth, and important for
            sustaining good health.
          </p>
        </div>
        <div className="triple-content-box">
          <h3>Reflection</h3>
          <img src={reflection} className="triple-content-icon" alt="t" />
          <p>
            A body is only as powerful as the devices that run it, and a sound
            mind is the most important tool in the aresenal.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default ThreePillars;
