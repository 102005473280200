import React from "react";

import colin from "../img/colin/transform-2.jpg";
import logo from "../img/logo-smaller.png";

class AboutUs extends React.Component {
  render() {
    return (
      <section className="colyma-section about-us">
        <img src={logo} className="logo-bg-image" alt="t" />
        <h1>About Us</h1>
        <div className="dual-colyma-content">
          <div className="dual-text">
            <p>My name is Colin Barile.</p>
            <p className="text-small">
              I’m the creator and owner of Lionheart Athletic. I wanted to share
              my story and how Lionheart Athletic came to be.
            </p>
            <p className="text-small">
              Growing up in a suburban New York town, in high school I was a shy
              overweight kid, I kept to myself and small group of friends. I
              loved sports, but hated running. I would tryout for teams,
              specifically basketball, and quit half way through because I could
              not handle the running. It hurt too much.{" "}
            </p>
            <p className="text-small">
              After high school, I regretted always quitting. I got fed up with
              being told I was lazy, I would never be in shape, and I couldn’t
              do it. A fire was lit inside of me. Though it was small at the
              time, it was lit. I started my journey and it would soon become my
              passion and career.{" "}
            </p>
          </div>

          <img className="clickable-z colin-transform" src={colin} alt="" />
        </div>

        <p className="text-small">
          Over time the fire grew. I learned about exercise and nutrition and
          put it to action. I started seeing results and for the first time in
          my life a new found pride filled me. Two years in, I was down 50
          pounds and looking like a new me.{" "}
        </p>
        <p className="text-small">
          I always knew I wanted to change lives and help people. It was
          something I loved to do, but I never knew how I would do it. Then I
          realized how I had changed my own life and could do the same for
          others.{" "}
        </p>
        <p className="text-small">
          I set out on my journey to be an elite trainer. Over the years I’ve
          volunteered, learned from the best, and worked at different gyms with
          numerous clients. There is nothing more satisfying then seeing a
          client hit a goal. It’s all them, I just motivate and give the tools.{" "}
        </p>
        <p className="text-small">
          I’ve always had dreams of opening my own business. Lionheart Athletic
          started as just that, a dream. Now that Lionheart Athletic is real,
          it’s still all about dreams. It’s of course training, fitness and
          nutrition, but I wanted it to be so much more. Pushing for your
          dreams, finding that fire that pushes you to do things you never
          thought you could do! It’s personal growth and living life in the way
          you always wanted. Finding the strength to do the impossible and
          becoming the best version of yourself. Digging deep and living with
          the heart of a lion! This dream lives in all of us and we can make it
          come true together. Just put in the work, stay with it, and watch it
          bloom.
        </p>
        <h4>Feel the pride, join the pride and live Lionhearted! </h4>
      </section>
    );
  }
}

export default AboutUs;
