import React from "react";
import colystock from "../img/stock/colin-stock.jpg";

class ScheduleASession extends React.Component {
  render() {
    return (
      <>
        <img
          style={{ top: "-35rem", left: "-30rem", width: "1600px" }}
          src={colystock}
          className="background-image-ath"
          alt="t"
        />
        <h1>Schedule A Session</h1>
        <p>
          Get personalized recomendations based on your unique body type, diet,
          and activity. Let Lionheart Athletic guide you to reach your goals
          with a professional yet fun approach that will make going to the gym
          feel more like an opportunity than a chore.
        </p>
        <h4>
          We offer in home training, or book a session at{" "}
          <a className="clickable-z" href="https://trackyourfitnessnj.com/">
            Track Your Fitness
          </a>{" "}
          in Allendale, NJ.<br /> Schedule an appointment today!
        </h4>
      </>
    );
  }
}

export default ScheduleASession;
