import React from "react";
import stockimage from "../img/stock/adult-blur-close-up-241456.jpg";
import black from "../img/black-lionheart-athletic.png";

class LionheartHeader extends React.Component {
  render() {
    return (
      <section className="colyma-section colyma-header">
        <img
          style={{ top: "-25rem" }}
          src={stockimage}
          className="background-image-ath"
          alt="t"
        />

        <img src={black} className="lionheart-logo-full" alt="lionheart" />
      </section>
    );
  }
}

export default LionheartHeader;
